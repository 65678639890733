import { createAsyncThunk } from "@reduxjs/toolkit";
import { Get, postRequest, uploadCSVFile } from "../../../general/https";
import { GenerateIceBreakePayload, GenerateIceBreakeResponse, SendDMPayload, SendDMResponse, SendInvitationPayload, SendInvitationResponse, UploadCSVPayload, UploadCSVResponse } from "./types";
import { checkErrorStatus } from "../../../general/checkErrorStatus";

export const generateIceBreake = createAsyncThunk<GenerateIceBreakeResponse, GenerateIceBreakePayload>(
  'popupSlice/generateIceBreake',
  async ({
    id,
    leadConnectionPosition,
    userFirstLastName,
    profileImg,
    likedYourComments,
    repliedToYourComments,
    leadIdentitySn,
    haveNewActivity
  }, {rejectWithValue}) => {
    try {
      const response = await Get(`/prompt/generate-message/${leadIdentitySn}`, true)
      if (response?.data?.error || !response) {
        throw new Error(response.data.error)
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const uploadCSV = createAsyncThunk<UploadCSVResponse, UploadCSVPayload>(
  'popupSlice/uploadCSV',
  async ({file}, {rejectWithValue}) => {
    try {
      const response = await uploadCSVFile('/parsing/load-db-of-leads-using-csv-links', file, false)
      checkErrorStatus(response)
      console.log(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const sendInvitation = createAsyncThunk<SendInvitationResponse, SendInvitationPayload>(
  'popupSlice/sendInvitation',
  async ({leadId, message}, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/lead-information/send-invitation`, {leadId, message}, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const sendDM = createAsyncThunk<SendDMResponse, SendDMPayload>(
  'popupSlice/sendDM',
  async ({leadId, message}, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/lead-information/send-direct-message`, {leadId, message}, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)