import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckValidCookieResponse, sidebarSliceInitialState } from './types';
import { deleteLeadsAndUpdatedLeadList, markLeadsAsRead } from '../tableLeadsSlice/thunks';
import {Get} from '../../../general/https';
import { sendMessage } from '../newFeedSlice/thunks';
import { checkActivityByNotification } from '../leadsNotificationSlice/thunks';
import { sendDM, sendInvitation } from '../popupSlice/thunks';
import { getSideBarData } from './thunks';

export const checkValidCookie = createAsyncThunk<CheckValidCookieResponse>(
  'sidebarSlice/checkValidCookie',
  async (_, {rejectWithValue}) => {
    try {
      const response = await Get('/users-information/check-valid-cookie', true)
      return response
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const initialState: sidebarSliceInitialState = {
  replies: 0,
  totalCountOfLeads: 0,
  leadsLimit: 0,
  pendingReplies: 0,
  countOfRepliedFeed: 0,
  checkOfValidCookie: true,
  isDataLoading: false,
  checkingCookiesProcess: false,
  isSideBarWide: true,
  profileViews: 0
};

export const sidebarSlice = createSlice({
  name: 'sidebarSlice',
  initialState,
  reducers: {
    setReplies: (state, { payload }: PayloadAction<number>) => {
      state.replies = payload;
    },
    setTotalCountOfLeads: (state, { payload }: PayloadAction<number>) => {
      state.totalCountOfLeads = payload;
    },
    setLeadsLimit: (state, { payload }: PayloadAction<number>) => {
      state.leadsLimit = payload;
    },  
    setPendingReplies: (state, { payload }: PayloadAction<number>) => {
      state.pendingReplies = payload;
    }, 
    setCountOfRepliedFeed: (state, { payload }: PayloadAction<number>) => {
      state.countOfRepliedFeed = payload;
    }, 
    setCheckOfValidCookie: (state, { payload }: PayloadAction<boolean>) => {
      state.checkOfValidCookie = payload;
    },
    setDataLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isDataLoading = payload;
    },
    setSideBarWide: (state, { payload }: PayloadAction<boolean>) => {
      state.isSideBarWide = payload;
    },    
  },
  extraReducers: (builder) => {
    //Get sidebar data
    builder.addCase(getSideBarData.pending, (state, action) => {
      state.isDataLoading = true;
    })
    builder.addCase(getSideBarData.fulfilled, (state, action) => {
      const {totalLeadsCount, leadsLimit, replies, pendingReplies, countOfRepliedFeed, userInfo} = action.payload;
      state.totalCountOfLeads = totalLeadsCount;
      state.leadsLimit = leadsLimit;
      state.replies = replies;
      state.pendingReplies = pendingReplies;
      state.countOfRepliedFeed = countOfRepliedFeed;
      state.profileViews = userInfo.profileViews
      state.isDataLoading = false;
    }) 
    builder.addCase(getSideBarData.rejected, (state, action) => {
      state.isDataLoading = false;
    })  
    //Delete And Update Lead List
    builder.addCase(deleteLeadsAndUpdatedLeadList.fulfilled, (state, action) => {      
      state.totalCountOfLeads = state.totalCountOfLeads - action.meta.arg.selectedRows.length
    })
    //Check cookies valid
    builder.addCase(checkValidCookie.pending, (state) => {
      state.checkingCookiesProcess = true;
    })
    builder.addCase(checkValidCookie.fulfilled, (state, action) => {
      const checkCookieFromLocalStorage = localStorage.getItem('checkOfValidCookie') === 'true' ? true : false
      if (checkCookieFromLocalStorage !== action.payload.isValid) {
        localStorage.setItem('checkOfValidCookie', String(action.payload.isValid))
      }
      if (action.payload.newPicture !== '') {
        localStorage.setItem('profilePic', action.payload.newPicture);
      }
      state.checkOfValidCookie =  action.payload.isValid
      state.checkingCookiesProcess = false;
    })
    builder.addCase(checkValidCookie.rejected, (state, action) => {
      state.checkOfValidCookie = false;
      state.checkingCookiesProcess = false;
    })
    //Send message from feed page
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.countOfRepliedFeed += 1
    })
    //Update leads notifications
    builder.addCase(checkActivityByNotification.fulfilled, (state, action) => {
      if(action.payload?.pendingReplies) {
        state.pendingReplies = action.payload?.pendingReplies
      }
    })
    //Send invitation
    builder.addCase(sendInvitation.fulfilled, (state, action) => {
      if(action.payload.isSent) {
        if(state.pendingReplies > 0 && action.meta.arg.isLeadHaveNewActivity) {
          state.pendingReplies -= 1;
        }
        
        state.replies += 1; 
      } 
    })
    //Send DM
    builder.addCase(sendDM.fulfilled, (state, action) => {
      if(action.payload.isSent) {
        if(state.pendingReplies > 0 && action.meta.arg.isLeadHaveNewActivity) {
          state.pendingReplies -= 1;
        }
        state.replies += 1; 
      } 
    })
    //Mark as Read Leads
    builder.addCase(markLeadsAsRead.fulfilled, (state, action) => {
      const {selectedRows, isAllSelected} = action.meta.arg
      
      if(isAllSelected) {
        state.pendingReplies = 0;
        return;
      }

      if(state.pendingReplies > 0 && state.pendingReplies - selectedRows.length >= 0) {
        state.pendingReplies -= selectedRows.length;
      }
    })     
  }    
});

export const sidebarSliceActions = sidebarSlice.actions;
export const sidebarReducer = sidebarSlice.reducer;
