import React, { useEffect, useState } from 'react';
import styles from "./ImportCSV.module.scss";
import { usePopupData } from '../../../utils/redux/storeHooks/dataHooks/usePopupData';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Button, CircularProgress, IconButton, styled, TextField, Typography } from '@mui/material';
import UserInformation from '../../../types/entities/user_information';
import { getUserInformationFromlocalStorage } from '../../../utils/general/get_set_user_information';
import { useAutomationData } from '../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useTableLeadsActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TeamListFlow } from './features/TeamListFlow';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useSidebarData } from '../../../utils/redux/storeHooks/dataHooks/useSidebarData';

export const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '30px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    margin: 0,

    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px'
    },
    '& .MuiInputBase-input': {
      caretColor: 'rgba(109, 48, 183, 1)',
    },
  },
})

export const PopupImportCSV = () => {
  const { setPopupImportCSVOpen, uploadCSV } = usePopupActions();
  const { isUploadingCSVProcess } = usePopupData()
  const { uploadingSnLink } = useTableLeadsData();
  const { checkOfValidCookie } = useSidebarData()
  // const { getListOfTeamMembersAndCheckCookies } = useTableLeadsActions();
  const { isAutomationRunning } = useAutomationData();
  const [selectUserForParse, setSelectUserForParse] = useState<UserInformation | null>(null);
  const [file, setfile] = useState<File | null>(null);

  // const organizationId = getUserInformationFromlocalStorage().organizationId

  const closePopUp = () => {
    setPopupImportCSVOpen(false)
  }

  // const onSelectUserHandler = (user: UserInformation) => {
  //   if (selectUserForParse?.uidd === user.uidd) {
  //     setSelectUserForParse(null)
  //     return;
  //   }
  //   setSelectUserForParse(user)
  // }

  const handleUpload = () => {
    if (file) {
      uploadCSV({ file })
    }
  };

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0]
    if (file) {
      setfile(file)
    }
  };

  const downloadExample = () => {
    const link = document.createElement("a");
    link.download = `example.csv`;
    link.href = "/example.csv";
    link.click();
  };

  // useEffect(() => {
  //   const userInformation = getUserInformationFromlocalStorage()
  //   getListOfTeamMembersAndCheckCookies({ userInformation });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [organizationId])

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Import CSV
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>

        <Box className={styles.content__upload}>
          <StyledTextField sx={{ width: '100%' }} value={file?.name || ''} disabled />
          <Button
            variant='contained'
            component='label'
            className={styles.content__uploadButton}
          >
            <input
              type="file"
              accept=".xls, .xlsx, .csv"
              hidden
              onChange={(event) => handleChangeFile(event)}
            />
            Select file
          </Button>
        </Box>
        <Typography className={styles.content__note}>
          *Please note that the limit for importing leads is 1000/day.
        </Typography>

        {/* <Box>
          <Typography sx={{
            fontFamily: 'Kanit',
            fontSize: 16,
            marginBottom: '8px',
            textAlign: 'center',
            marginTop: '8px'
          }}>
            Select user for parsing
          </Typography>
          <TeamListFlow
            onSelectUserHandler={onSelectUserHandler}
            selectUserForParse={selectUserForParse}
          />
        </Box> */}

        <Box className={styles.content__download}>
          <Typography className={styles.content__downloadText}>
            Check the following example to ensure your file fits appropriately.
          </Typography>
          <Button
            component='button'
            className={styles.content__downLoadButton}
            onClick={downloadExample}
          >
            <img src='/download.svg' alt='' />
            <Typography className={styles.content__downLoadButtonText}>
              Download sample CSV file
            </Typography>
          </Button>
        </Box>

      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <Button
          className={styles.footer__action}
          component="label"
          variant="contained"
          onClick={handleUpload}
          disabled={isUploadingCSVProcess
            || !file
            || isAutomationRunning
            || !!uploadingSnLink
            || !checkOfValidCookie

            // || !selectUserForParse
            // || !selectUserForParse.isCookieValidForNow
          }
        >
          {isUploadingCSVProcess
            ? <CircularProgress size={22} sx={{ color: 'white' }} /> : (
              <>
                <img src='/upload_icon.svg' alt='' />
                Import CSV
              </>
            )}
        </Button>
      </Box>
    </Box>
  );
};
